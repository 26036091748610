* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden!important;
}

.custom-switch.ant-switch-checked {
    background-color: var(--color-primario)!important;
}

.custom-switch.ant-switch:not(.ant-switch-checked) {
    background-color: transparent;
}

.drag__style {
    padding: 10px 25px;
    border-bottom: 1.5px solid #000;
    margin-bottom: 10px;
}

.skin__scrollbar::-webkit-scrollbar {
    width: 7px;
}

.skin__scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.skin__scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}